import React from 'react'
import CworkForm from './CeoReviewForm'
import CworkList from './CworkList'
import Navbar from '../../Pages/components/Navbar'
import { Box, Typography } from '@mui/material'
import Sidenav from './Sidenav'
import CeoReviewForm from './CeoReviewForm'
import CeoReviewList from './CeoReviewList'


const CeoReview = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h3' align='center'  > CEO review on Works/Projects </Typography><hr/>
            <Box height={10}/>
            
              <CeoReviewForm />
           <CeoReviewList/> 
            </Box>
          </Box>
        </div>
  )
}

export default CeoReview