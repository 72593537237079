import React, { useEffect } from 'react'


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Card, Grid2 as Grid, Stack } from '@mui/material';
import ProjectList from './ProjectList';
import CountUp from 'react-countup';
import { fetchWorks, fetchWorksCompleted, fetchWorksCompletedInfra1, fetchWorksCompletedInfra2, fetchWorksDelay, fetchWorksDelayInfra1, fetchWorksDelayInfra2, fetchWorksInfra1, fetchWorksInfra2, fetchWorksOnhold, fetchWorksOnholdInfra1, fetchWorksOnholdInfra2, fetchWorksPending, fetchWorksPendingInfra1, fetchWorksPendingInfra2, fetchWorksUnderProgressInfra1, fetchWorksUnderProgressInfra2 } from '../../Redux/workReducer'
import { fetchWorksUnderProgress } from '../../Redux/workReducer';
import { useDispatch, useSelector } from 'react-redux';
import ProjectListInfra1 from './ProjectListInfra1';
import ProjectListInfra2 from './ProjectListInfra2';
import JvisitTable from './JvisitTable';
import XvisitTable from './XvisitTable';
import SvisitTable from './SvisitTable';
import CvisitTable from './CvisitTable';
import CworkList from './CworkList';
import { Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem',color:'#ff' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ProjectListByDivision=()=> {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const navigate=useNavigate();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(fetchWorks())

    dispatch(fetchWorksUnderProgress())
    dispatch(fetchWorksDelay())
    dispatch(fetchWorksCompleted())
    dispatch(fetchWorksOnhold())
    dispatch(fetchWorksPending())

    dispatch(fetchWorksUnderProgressInfra1())
    dispatch(fetchWorksDelayInfra1())
    dispatch(fetchWorksCompletedInfra1())
    dispatch(fetchWorksOnholdInfra1())
    dispatch(fetchWorksPendingInfra1())

    dispatch(fetchWorksUnderProgressInfra2())
    dispatch(fetchWorksDelayInfra2())
    dispatch(fetchWorksCompletedInfra2())
    dispatch(fetchWorksOnholdInfra2())
    dispatch(fetchWorksPendingInfra2())



    dispatch(fetchWorksInfra1())
    dispatch(fetchWorksInfra2())
      },[dispatch])
      const works = useSelector((state) => state.works.works);

      const worksUnderProgressCount = useSelector((state) => state.works.worksUnderProgress[0]?.twcount);
      const worksDelayCount = useSelector((state) => state.works.worksDelay[0]?.twcountDelay);
      const worksCompletedCount = useSelector((state) => state.works.worksCompleted[0]?.twcountCompleted);
      const worksOnholdCount = useSelector((state) => state.works.worksOnhold[0]?.twcountOnhold);
      const worksPendingCount = useSelector((state) => state.works.worksPending[0]?.twcountPending);
      
      const worksUnderProgressCountInfra1 = useSelector((state) => state.works.worksUnderProgressInfra1[0]?.twcount);
      const worksDelayCountInfra1 = useSelector((state) => state.works.worksDelayInfra1[0]?.twcountDelay);
      const worksCompletedCountInfra1 = useSelector((state) => state.works.worksCompletedInfra1[0]?.twcountCompleted);
      const worksOnholdCountInfra1 = useSelector((state) => state.works.worksOnholdInfra1[0]?.twcountOnhold);
      const worksPendingCountInfra1 = useSelector((state) => state.works.worksPendingInfra1[0]?.twcountPending);
      
      const worksUnderProgressCountInfra2 = useSelector((state) => state.works.worksUnderProgressInfra2[0]?.twcount);
      const worksDelayCountInfra2 = useSelector((state) => state.works.worksDelayInfra2[0]?.twcountDelay);
      const worksCompletedCountInfra2 = useSelector((state) => state.works.worksCompletedInfra2[0]?.twcountCompleted);
      const worksOnholdCountInfra2 = useSelector((state) => state.works.worksOnholdInfra2[0]?.twcountOnhold);
      const worksPendingCountInfra2 = useSelector((state) => state.works.worksPendingInfra2[0]?.twcountPending);
      
      const worksInfra1Count=useSelector((state) => state.works.worksInfra1);
      const worksInfra2Count=useSelector((state) => state.works.worksInfra2);

      // const activeCount = useSelector((state) => Array.isArray(state.orgus.orgus1)&&state.orgus.orgus1.length&&state.orgus.orgus1[0].tcount);
  return (


    <div>
      <>
      
      <Grid spacing={2} container>
      <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                   Details of All Division Projects/Works
                  </Typography>
                  <Box height={20}/>
                 
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkred'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsAllPending")}
                  >
                 Yet to Start Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksPendingCount} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='orange'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsAllUprogress")}
                  >
                 Under Progress Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksUnderProgressCount} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='green'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsAllComplete")}
                  >
                 Completed Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksCompletedCount} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkblue'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projects")}
                  >
                 Projects/Works<Send/>:{" "}               
                    <CountUp delay={0.3} end={works.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Infra-I
                  </Typography>
                  <Box height={20}/>
                 
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkred'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1Pending")}
                  >
                 Yet to Start Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksPendingCountInfra1} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='orange'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1Uprogress")}
                  >
                 Under Progress Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksUnderProgressCountInfra1} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='green'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1Complete")}
                  >
                 Completed Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksCompletedCountInfra1} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkblue'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1")}
                  >
                 Infra-I Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={worksInfra1Count.length} duration={2.9} />
                  </Typography>
                  {/* <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1")}
                  >
                 Infra-I Delay Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksDelayCountInfra1} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra1")}
                  >
                 Infra-I On Hold Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksOnholdCountInfra1} duration={2.9} />
                  </Typography> */}
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Infra-II
                  </Typography>
                  <Box height={20}/>
                

                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkred'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra2Pending")}
                  >
                 Yet to Start Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksPendingCountInfra2} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='orange'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra2Uprogress")}
                  >
                Under Progress Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksUnderProgressCountInfra2} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='green'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra2Complete")}
                  >
                 Completed Works<Send/>:{" "}                
                    <CountUp delay={0.3} end={worksCompletedCountInfra2} duration={2.9} />
                  </Typography>

                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    color='darkblue'
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/projectsInfra2")}
                  >
                 Projects/Works<Send/>:{" "}                   
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
           
          </Grid>
      {/* <Grid spacing={2} container>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Electrical
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/electricalwork")}
                  >
                 Electrical Works<Send/>:{" "}
                   
                    <CountUp delay={0.3} end={worksInfra1Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Smart City
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/smartcitywork")}
                  >
                 Smart City Projects/Works<Send/>:{" "}
                  
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    GIS Division
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/giswork")}
                  >
                 GIS Division Projects/Works<Send/>:{" "}
                  
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
          </Grid> */}
      {/* <Grid spacing={2} container>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                   IT Division
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/itwork")}
                  >
                 IT Projects/Works<Send/>:{" "}
                 
                    <CountUp delay={0.3} end={worksInfra1Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                   Legal Division
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/xen/work")}
                  >
                 Legal Division Projects/Works<Send/>:{" "}
                  
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>

            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Mobility
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/ceo/mobilitywork")}
                  >
                 Mobility Projects/Works<Send/>:{" "}
             
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>

            
          </Grid> */}

 {/* to be delete in future */}
      {/* <Stack spacing={2}>

<Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel6d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
        
          <Grid>
        <Grid item xs={12}> 
            <Stack direction={'row'} color={'#ffffff'}>
          <Typography
            variant="h5"
            p={1}
   
            color="#ffffff"
      
          >
           All Division Works/Projects
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: # <CountUp delay={0.3} end={works.length}duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: # <CountUp delay={0.3} end= {worksUnderProgressCount} duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: # <CountUp delay={0.3} end= {worksDelayCount} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: # <CountUp delay={0.3} end= {worksCompletedCount} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= {worksPendingCount} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: # <CountUp delay={0.3} end= {worksOnholdCount} duration={2.9} /></Typography>
            

             </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectList/>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
         
          <Grid>
        <Grid item xs={12}>
            <Stack direction={'row'} color={'#ffffff'}  spacing={5} >
          <Typography
            variant="h5"
            p={1}
            color="#ffffff"
            sx={{ backgroundColor: "rgb(55,5,.84)" }}
          >
            Infra-I
          </Typography> 
          <Typography variant="h5" px={5}>All Works/Projects: #<CountUp delay={0.3} end= {worksInfra1Count.length} duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: #<CountUp delay={0.3} end= {worksUnderProgressCountInfra1} duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: #<CountUp delay={0.3} end= {worksDelayCountInfra1} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= {worksCompletedCountInfra1} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= {worksPendingCountInfra1} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= {worksOnholdCountInfra1} duration={2.9} /></Typography>
            
          </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ProjectListInfra1/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>

          <Grid>
        <Grid item xs={12}>
            <Stack direction={'row'} color={'#ffffff'}>
          <Typography
            variant="h5"
            p={1}
            color="#ffffff"
            sx={{ backgroundColor: "rgb(55,5,.84)" }}
          >
            Infra-II
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: #<CountUp delay={0.3} end= {worksInfra2Count.length} duration={2.9} /></Typography>
          <Typography px={5} variant="h5">Under Progress: #<CountUp delay={0.3} end= {worksUnderProgressCountInfra2} duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: #<CountUp delay={0.3} end= {worksDelayCountInfra2} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= {worksCompletedCountInfra2} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= {worksPendingCountInfra2} duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= {worksOnholdCountInfra2} duration={2.9} /></Typography>
          
        
          </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectListInfra2/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
          
          <Grid>
        <Grid item xs={12}> 
            <Stack direction={'row'} color={'#ffffff'}>
          <Typography
            variant="h5"
            p={1}
           // width={'50%'}
            color="#ffffff"
            // sx={{ backgroundColor: "rgb(55,5,.84)" }}
          >
            Mobility
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: <CountUp delay={0.3} end= "55" duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: <CountUp delay={0.3} end= "5" duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: <CountUp delay={0.3} end= "35" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= "2" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            
      
          </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectList/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>

          <Grid>
        <Grid item xs={12}> 
            <Stack direction={'row'} color={'#ffffff'}>
          <Typography
            variant="h5"
            p={1}
      
            color="#ffffff"
       
          >
           Electrical
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: <CountUp delay={0.3} end= "55" duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: <CountUp delay={0.3} end= "5" duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: <CountUp delay={0.3} end= "35" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= "2" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            
          
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectList/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
        
          <Grid>
        <Grid item xs={12}> 
            <Stack direction={'row'} color={'#ffffff'}>
          <Typography
            variant="h5"
            p={1}
           
            color="#ffffff"
            
          >
           Urban Planning
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: <CountUp delay={0.3} end= "55" duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: <CountUp delay={0.3} end= "5" duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: <CountUp delay={0.3} end= "35" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= "2" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            
       
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectList/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
        
          <Grid>
        <Grid item xs={12}> 
            <Stack direction={'row'} color={'#ffffff'} spacing={2}>
          <Typography
            variant="h5"
            p={1}
       
            color="#ffffff"
     
          >
           Urban Environment
          </Typography>
          <Typography variant="h5" px={5}>All Works/Projects: <CountUp delay={0.3} end= "55" duration={2.9} /></Typography>
           <Typography px={5} variant="h5">Under Progress: <CountUp delay={0.3} end= "5" duration={2.9} /></Typography> 
           <Typography px={5} variant="h5">Delay: <CountUp delay={0.3} end= "35" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Completed: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">Pending: # <CountUp delay={0.3} end= "2" duration={2.9} /></Typography>
            <Typography px={5} variant="h5">On Hold: #<CountUp delay={0.3} end= "15" duration={2.9} /></Typography>
            
        
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ProjectList/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
       
          <Grid container spacing={2}>
        <Grid item xs={12}> 
            <Stack  color={'#ffffff'} spacing={2}>
          <Typography
            variant="h5"
            p={1}
       
            color="#ffffff"
        
          >
          JE Visit Information
          </Typography>
         
         
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <JvisitTable/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
          
          <Grid>
        <Grid item xs={12}> 
            <Stack  color={'#ffffff'} spacing={2}>
          <Typography
            variant="h5"
            p={1}
        
            color="#ffffff"
          
          >
          XEN Visit Information
          </Typography>
         
         
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <XvisitTable/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel_9'} onChange={handleChange('panel_9')}>
        <AccordionSummary aria-controls="panel_9d-content" id="panel_9d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>
      
          <Grid>
        <Grid item xs={12} > 
            
          <Typography
            variant="h5"
            p={1}
        
            color="#ffffff"
            
          >
          SE Visit Information
          </Typography>
         
         
       
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <SvisitTable/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel_8'} onChange={handleChange('panel_8')}>
        <AccordionSummary aria-controls="panel_8d-content" id="panel_8d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>

          <Grid>
        <Grid item xs={12}> 
            <Stack  color={'#ffffff'} spacing={2}>
          <Typography 
            variant="h5"
            p={1}
          alignItems={'center'}
        
            color="#ffffff"
          
          >
          CE Visit Information
          </Typography>
         
          
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <CvisitTable/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel_7'} onChange={handleChange('panel_7')}>
        <AccordionSummary aria-controls="panel_7d-content" id="panel_7d-header" sx={{ backgroundColor: "rgb(55,5,.84)" }}>

          <Grid>
        <Grid item xs={12}> 
            <Stack  color={'#ffffff'} spacing={2}>
          <Typography 
            variant="h5"
            p={1}
          alignItems={'center'}
        
            color="#ffffff"
          
          >
          Contractor/Agency Work Information
          </Typography>
         
          
           </Stack>
        </Grid>
      </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <CworkList/>
          </Typography>
        </AccordionDetails>
      </Accordion>

      

      </Stack> */}
      </>
    </div>
  );
}

export default ProjectListByDivision