import React, { useEffect } from 'react'
import Sidenav from './Sidenav';
import Navbar from '../../Pages/components/Navbar';
import { Box, Card, CardContent, Grid2 as Grid, Stack, Typography } from '@mui/material';
import CountUp from "react-countup";
import { fetchWorksCreatedBy } from "../../Redux/workReducer";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Send } from '@mui/icons-material';

const ContractorDashboard = () => {
  const navigate=useNavigate();

  const dispatch = useDispatch();
  const works = useSelector((state) => state.works.worksCreatedBy);

  useEffect(() => {
    // console.log(venders);
 
  
     dispatch(fetchWorksCreatedBy());
   }, [dispatch]);

  

 
  return (
    <div>
    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
 
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>Contractor Dashbaord</Typography><hr/>
      <Box height={20}/>
        <Grid container spacing={2}>
        <Grid size={6}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Projects/Works
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/contractor/projects")}
                  >
                 View All Projects/Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={works.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
          {/* <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white"}} >
         
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color={'lightblue'}>
                 Contractor Assigned Projects: <CountUp delay={0.3} end= "50" duration={2.9} />
            
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'lightgreen'}>
                  Active: <CountUp delay={0.3} end= "45" duration={2.9} />
               
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'#fe5644'}>
                  On Hold: <CountUp delay={0.3} end= "5" duration={2.9} />
            
                </Typography>
               
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Pending Projects...:<CountUp delay={0.3} end= "42" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> 
              projects <CountUp delay={0.3} end= "8" duration={2.9} />
                </Typography>
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Under Progress Projects:<CountUp delay={0.3} end= "6" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} />
                  projects <CountUp delay={0.3} end= "8" duration={2.9} />
                </Typography>
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                Completed Projects: 
               </Typography>
               <Typography variant="body2" color="#ffffff">
                <CountUp delay={0.3} end= "6" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
            Deley Projects:
               </Typography>
               <Typography variant="body2" color="#ffffff" >
               <CountUp delay={0.3} end= "44" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} /> */}
        </Grid>
       
      </Box>
    </Box>
  </div>
  );
}

export default ContractorDashboard