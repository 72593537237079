import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJvisits, deleteJvisit, fetchImages } from "../../Redux/jvisitSlice";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../Redux/api";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const JvisitTable = () => {
  const dispatch = useDispatch();
  const { jvisits } = useSelector((state) => state.jvisits);
  const { jvimages } = useSelector((state) => state.jvisits.jvimages);

  useEffect(() => {
    dispatch(fetchJvisits());
    dispatch(fetchImages());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteJvisit(id));
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Reviews", width: 200 },
    { field: "remark", headerName: "Remarks", width: 100 },
    { field: "created", headerName: "Created Date", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 100 },
    {
      field: "jvimages",
      headerName: "Images",
      width: 300,
      renderCell: (params) => (
        <div style={{display:'flex'}}>
          {params.row.jvimages.map((image1) => (
            <>
              <Stack spacing={2} direction={"row"}>
                <img
                  src={`${BASE_URL}/api/jvisits/image/${image1.jvimageName}`}
                  alt={image1.jvimageName}
                  key={image1.id}
                  width={25}
                />{" "}
                <a
                  target="_blank"
                  href={`${BASE_URL}/api/jvisits/image/${image1.jvimageName}`}
                >
                  View {image1.id}
                </a>{" "}
              </Stack>
            </>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    // {
    //     field: "Action",
    //     headerName: "Action",
    //     description: "This column has a value getter and is not sortable.",

    //     width: 60,
    //     renderCell: ({ row }) => (
    //       <IconButton onClick={() => handleEdit(row)}>
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
  ];

  return (
    <>
      {/* <Grid2 container spacing={3}>
        <Grid2 item xs={6}>123s</Grid2>
        <Grid2 item xs={6}>
        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 56, justifyContent: 'center', position:"relative" }}>
        <Avatar alt="User" src={rose} sx={{
          width: 80,
          height: 80,
          position: "absolute",
          left: -40,  // 50% overlap outside the card
          top: -5,
          zIndex:20
        }}/>
      </Box>
      <CardContent>
        <Typography variant="h5" component="div">
          Title
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Some description goes here.
        </Typography>
      </CardContent>
    </Card>
        </Grid2>
      </Grid2> */}

      <div style={{ height: 'auto', width:'95%' ,alignItems:'center'}}>
        <DataGrid rows={jvisits} columns={columns}   getRowId={(row) => row.id} pageSize={10} getRowHeight={() => 'auto'}
        sx={{
          boxShadow: 20,
          border: 2,
          borderColor: "green",
          "& .MuiDataGrid-cell": {
            color: "darkblue",
            borderColor: "#292524",
          },
          "& .MuiDataGrid-columnHeader": {
            // backgroundColor: "#0c0a09",
            opacity:'100%',
            fontWeight:'bold',
            fontSize:20,
            width:'100%',
            alignContent:'center',
            color: "darkaqua",
          }
        }}
      
        />
      </div>
    </>
  );
};

export default JvisitTable;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { BASE_URL } from '../../Redux/apiConfig'

// import { DataGrid } from "@mui/x-data-grid";
// import {
//   Avatar,
//   Box,
//   Button,
//   Card,
//   CardContent,
//   Grid,
//   Grid2,
//   IconButton,
//   ListItem,
//   ListItemText,
//   Typography,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { fetchImages, fetchJvisits } from "../../Redux/jvisitSlice";
// import { Link } from "react-router-dom";

// const JvisitTable = () => {

//   const dispatch = useDispatch();
//   const { jvisits } = useSelector((state) => state.jvisits);
//   const { jvimages } = useSelector((state) => state.jvisits.jvimages);

//   useEffect(() => {
//     dispatch(fetchJvisits());
//     // dispatch(fetchImages());
//   }, [dispatch]);

// //   const handleDelete = (id) => {
// //     if (window.confirm("Are you sure you want to delete this Visit?")) {
// //       dispatch(deleteJvisit(id));
// //     }
// //   };

//   const columns = [
//     { field: "id", headerName: "ID", width: 70 },
//     { field: "title", headerName: "Title", width: 200 },
//     { field: "remark", headerName: "Remarks", width: 100 },
//     {
//       field: "jvimages",
//       headerName: "Images",
//       width: 300,
//       renderCell: (params) => (
//         <div>
//           {params.row.jvimages?.map((image) => (
//             <>

// {/* <ListItem key={image.id}>
//                     <ListItemText primary={image.jvimageName} secondary={`${image.jvimagePath}`} />
//            <Link  target="_blank" to={`${BASE_URL}/api/jvisits/image/${image.jvimageName}`} >View {image.id}</Link>
//            <img key={image.id}  src={`${BASE_URL}/api/jvisits/image/${image.jvimageName}`}  style={{ width: '200px', height: 'auto', margin: '10px' }}/>
//                 </ListItem> */}
//               <a
//                 target="_blank"
//                 href={`${BASE_URL}/api/jvisits/image/${image.jvimageName}`}
//               >
//                 View {image.id}
//               </a>
//               <img
//                 src={`${BASE_URL}/api/jvisits/image/${image.jvimageName}`}
//                 alt={image.jvimageName}
//                 key={image.id}
//                 width={150}
//                 height={150}
//               />
//             </>
//           ))}
//         </div>
//       ),
//     },
//     // {
//     //   field: "actions",
//     //   headerName: "Actions",
//     //   width: 150,
//     //   renderCell: (params) => (
//     //     <IconButton onClick={() => handleDelete(params.row.id)}>
//     //       <DeleteIcon />
//     //     </IconButton>
//     //   ),
//     // },
//     // {
//     //     field: "Action",
//     //     headerName: "Action",
//     //     description: "This column has a value getter and is not sortable.",

//     //     width: 60,
//     //     renderCell: ({ row }) => (
//     //       <IconButton onClick={() => handleEdit(row)}>
//     //         <EditIcon />
//     //       </IconButton>
//     //     ),
//     //   },
//   ];

//   return (
//     <>
//       {/* <Grid2 container spacing={3}>
//         <Grid2 item xs={6}>123s</Grid2>
//         <Grid2 item xs={6}>
//         <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
//       <Box sx={{ display: 'flex', alignItems: 'center', width: 56, justifyContent: 'center', position:"relative" }}>
//         <Avatar alt="User" src={rose} sx={{
//           width: 80,
//           height: 80,
//           position: "absolute",
//           left: -40,  // 50% overlap outside the card
//           top: -5,
//           zIndex:20
//         }}/>
//       </Box>
//       <CardContent>
//         <Typography variant="h5" component="div">
//           Title
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Some description goes here.
//         </Typography>
//       </CardContent>
//     </Card>
//         </Grid2>
//       </Grid2> */}

//       <div style={{ height: 400, width: "100%" }}>
//         <DataGrid rows={jvisits} columns={columns}  pageSize={5} />
//       </div>
//     </>
//   );
// };

// export default JvisitTable;
