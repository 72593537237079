import React from 'react'
import { Route } from 'react-router-dom'
import AdminRouter from './AdminRouter'
import CeoRouter from './CeoRouter'
import XenRouter from './XenRouter'

import ContractorRouter from './ContractorRouter'
import SeRouter from './SeRouter'
import CeRouter from './CeRoute'
import JeRouter from './JeRouter'
import ItadminRouter from './ItadminRouter'

const PrivateRoutes = () => {
    
  return [

<Route path='/admin/*' exact element={<AdminRouter />}/>,
<Route path='/itadmin/*' exact element={<ItadminRouter />}/>,
  <Route path='/ceo/*' exact element={<CeoRouter />}/>,
  <Route path='/ce/*' exact element={<CeRouter />}/>,
  <Route path='/se/*' exact element={<SeRouter />}/>,
  <Route path='/xen/*' exact element={<XenRouter />}/>,
  <Route path='/je/*' exact element={<JeRouter />}/>,
  <Route path='/contractor/*' exact element={<ContractorRouter />}/>

  ]
}

export default PrivateRoutes