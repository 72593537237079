import newaxios from "axios"

// export const API_BASE_URL="http://localhost:8080"
// export const BASE_URL="http://localhost:8080"
export const API_BASE_URL="http://api.erpfmda.in"
export const BASE_URL="http://api.erpfmda.in"


const api=newaxios.create({baseURL:API_BASE_URL})
    const jwt=localStorage.getItem("jwt");

//  api.defaults.headers.common["Authorization"]=`Bearer ${jwt}`
 api.defaults.headers.common["Content-Type"]="application/json";

 api.interceptors.request.use(config => {
    // const authToken = getCookie('jwt');
    const authToken=localStorage.getItem("jwt");
      config.headers={
        ...config.headers,
        ...(authToken && {
            'Authorization':`Bearer ${authToken}`
        })
      }
    
    return config;
  });

  api.interceptors.response.use(
    (response)=>response,
    (error)=>{
        if(error.response && error.response.status===401){
            console.log('call the refresh token api here Unathorised');
            
        }
        return Promise.reject(error)
    }
  );

 export default api;