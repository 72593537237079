// isLoggedIn=>

// import { getCookie } from "../../../Redux/apiConfig";

    export const isLoggedIn = () => {
    
        // const data=getCookie('jwt');
        const data=localStorage.getItem('jwt');
        
      
        return !!data
      };
      
      //doLogin=> data=>set to localstorage
      
      export const doLogin = (data,next) => {
        localStorage.setItem("data", JSON.stringify(data));
        next()
      };
      
      //doLogout=> remove from localStorage
      
      export const doLogout = (next) => {
        localStorage.removeItem("data");
        next()
      };
      
      //get currentUser
      export const getCurrentUserDetail = () => {
        if (isLoggedIn()) {
          return JSON.parse(localStorage.getItem("data")).user;
        } else {
          return undefined;
        }
      };
      
      export const getToken=()=>{
        if(isLoggedIn()){
          return JSON.parse(localStorage.getItem("data")).token
        }else{
          return null;
        }
      }
      