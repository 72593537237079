import React from 'react';
import ItadminDash from './ItadminDash';

const Itadmin = () => {
  return (
    <div>
      <ItadminDash/>
    </div>
  );
}

export default Itadmin;
