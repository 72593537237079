import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Ceo/components/Dashboard';
import AdminApp from '../Ceo/components/AdminApp';
import Ceo from '../Ceo/components/Ceo';
import File from '../Ceo/components/File';
import ProjectList from '../Ceo/components/ProjectList';
import CeoProjects from '../Ceo/components/CeoProjects';
import FileUpload from '../Ceo/components/Fileupload';
import Video from '../Ceo/components/Video';
import CeoProjectsInfra1 from '../Ceo/components/CeoProjectsInfra1';
import CeoProjectsInfra2 from '../Ceo/components/CeoProjectsInfra2';
import CeoProjectsInfra1Complete from '../Ceo/components/CeoProjectsInfra1Complete';
import CeoProjectsInfra1Pending from '../Ceo/components/CeoProjectsInfra1Pending';
import CeoProjectsInfra1Uprogress from '../Ceo/components/CeoProjectsInfra1Uprogress';

import CeoProjectsInfra2Complete from '../Ceo/components/CeoProjectsInfra2Complete';
import CeoProjectsInfra2Pending from '../Ceo/components/CeoProjectsInfra2Pending';
import CeoProjectsInfra2Uprogress from '../Ceo/components/CeoProjectsInfra2Uprogress';
import CeoProjectsAllComplete from '../Ceo/components/CeoProjectsAllComplete';
import CeoProjectsAllUprogress from '../Ceo/components/CeoProjectsAllUprogress';
import CeoProjectsAllPending from '../Ceo/components/CeoProjectsAllPending';
import CeoReview from '../Ceo/components/CeoReview';


const CeoRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Ceo />}/>
            <Route path='/dashboard' element={<Dashboard />}/>
           
            <Route path='/fileup' element={<File />}/>
            
            <Route path='/projects' element={<CeoProjects />}/>
            <Route path='/projectsInfra1' element={<CeoProjectsInfra1 />}/>
            <Route path='/projectsInfra1Complete' element={<CeoProjectsInfra1Complete />}/>
            <Route path='/projectsInfra1Uprogress' element={<CeoProjectsInfra1Uprogress />}/>
            <Route path='/projectsInfra1Pending' element={<CeoProjectsInfra1Pending />}/>

            <Route path='/projectsInfra2Complete' element={<CeoProjectsInfra2Complete />}/>
            <Route path='/projectsInfra2Uprogress' element={<CeoProjectsInfra2Uprogress />}/>
            <Route path='/projectsInfra2Pending' element={<CeoProjectsInfra2Pending />}/>


            <Route path='/projectsAllComplete' element={<CeoProjectsAllComplete />}/>
            <Route path='/projectsAllUprogress' element={<CeoProjectsAllUprogress />}/>
            <Route path='/projectsAllPending' element={<CeoProjectsAllPending />}/>

            <Route path='/creview/:workId' element={<CeoReview />}/>



            <Route path='/projectsInfra2' element={<CeoProjectsInfra2 />}/>
            
            <Route path='/fileup/:workId' element={<FileUpload />}/>

        </Routes>
    </div>
  );
}

export default CeoRouter;
