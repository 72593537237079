import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createCtvisit, updateCtvisit } from "../../Redux/ctvisitSlice";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid2 as Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";

const CtvisitForm = ({ ctvisit, isEdit, setCtvisit }) => {
  const { workId } = useParams();
  // const [latitude,setLatitude]=useState('')
  // const [longitude,setLongitude]=useState('');
  // useEffect(()=>{
  //     navigator.geolocation.getCurrentPosition((position)=>{
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //     })
  //     })
  const [review, setReview] = useState(ctvisit ? ctvisit.review : "");
  const [nod, setNod] = useState(ctvisit ? ctvisit.nod : "");
  const [nol, setNol] = useState(ctvisit ? ctvisit.nol : "");
  const [sdate, setSdate] = useState(ctvisit ? ctvisit.sdate : "");
  const [edate, setEdate] = useState(ctvisit ? ctvisit.edate : "");
  const [active, setActive] = useState(ctvisit ? ctvisit.active : true);

  const [remark, setRemark] = useState(ctvisit ? ctvisit.remark : "");
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setImages(e.target.files);
  };

  useEffect(() => {
    if (ctvisit) {
      setReview(ctvisit.review);
      setNod(ctvisit.nod);
      setNol(ctvisit.nol);
      setSdate(ctvisit.sdate);
      setEdate(ctvisit.edate);
      setActive(ctvisit.active);
      setRemark(ctvisit.remark);
    } else {
      setReview("");
      setNod("");
      setNol("");
      setSdate("");
      setEdate("");
      setActive(false);
      setRemark("");
    }
  }, [ctvisit]);

  const handleSubmit = () => {
    const formData = new FormData();
    // formData.append('name', name);
    // formData.append('age', age);
    formData.append(
      "ctvisit",
      new Blob(
        [
          JSON.stringify({
            review,
            nod,
            nol,
            sdate,
            edate,
            active,
            remark,
            workId: workId,
          }),
        ],
        { type: "application/json" }
      )
    );
    Array.from(images).forEach((image) => {
      formData.append("images", image);
    });

    if (ctvisit) {
      dispatch(updateCtvisit({ id: ctvisit.id, ctvisitData: formData }));
    } else {
      dispatch(createCtvisit(formData));
      setReview('');
      setNod('');
      setNol('');
      setSdate('');
      setEdate('');
      setActive(false);
      setRemark('');
    }
  };

  return (
    <>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 1024 }}
      >
        <Typography variant="h6">
          Contractor Work Info... <hr />
        </Typography>
        <form>
        <Grid container spacing={2}>
              <Grid item size={12}>
                {" "}
                <TextField
                  label="Work Nature"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Box height={5} />
           
              <Grid item size={4}>
                <TextField
                  fullWidth
                  label="No. Of Labour"
                  type="number"
                  value={nol}
                  onChange={(e) => setNol(e.target.value)}
                  margin="normal"
                />
              </Grid>

              <Grid item size={4}>
                <TextField
                  fullWidth
                  label="No. Of Days"
                  type="number"
                  value={nod}
                  onChange={(e) => setNod(e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item size={4}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  label="Start Date"
                  placeholder="Start Date"
                  value={sdate}
                  onChange={(e) => setSdate(e.target.value)}
                  margin="normal"
                />
              </Grid>

              <Grid item size={4}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  label="End Date"
                  placeholder="End Date"
                  value={edate}
                  onChange={(e) => setEdate(e.target.value)}
                  margin="normal"
                />
              </Grid>
              
              <Grid item size={12}>
                {" "}
                <TextField
                  label="Remarks"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item size={12}>
                {" "}
                <FormControlLabel
                  control={
                    <Switch
                    fullWidth
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Box height={5} />
              <input type="file" multiple onChange={handleFileChange} />
              <Button variant="contained" onClick={handleSubmit}>
                {ctvisit ? "Update" : "Create"}
              </Button>
        </Grid> </form>
      </Box>
    </>
  );
};

export default CtvisitForm;
