import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from './api';


export const fetchEmps = createAsyncThunk('emps/fetchEmps', async () => {
  const response = await axios.get(`/api/employees`);
  return response.data;
});

export const fetchEmpsIdName = createAsyncThunk('emps/fetchEmpsIdName', async (thunkAPI) => { 
  try {
    const response = await axios.get(`/api/employees/getempidname`);
    const data = response.data;
    console.log("this is my Data",data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

});

export const addEmp = createAsyncThunk('emps/addEmp', async (employee,thunkAPI) => {
  // const response = await axios.post(`/api/employees`, emp);
  // return response.data;

  try {
    const response = await axios.post(`/api/employees`,employee);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const updateEmp = createAsyncThunk('emps/updateEmp', async ({ id, emp }) => {
  const response = await axios.put(`/api/employees/${id}`, emp);
  return response.data;
});

export const deleteEmp = createAsyncThunk('emps/deleteEmp', async (id) => {
  await axios.delete(`/api/employees/${id}`);
  return id;
}); 

export const changeEmpStatus = createAsyncThunk('emps/changeEmpStatus', async ({ id, active }) => {
  const response = await axios.patch(`/api/employees/${id}/status?active=${active}`);
  return response.data;
});
const empsSlice = createSlice({
  name: 'emps',
  initialState: {
    emps: [],
    status: 'idle',
    error: null,
    empsIdName:[],
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.emps = action.payload;
      })
      .addCase(fetchEmps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // .addCase(fetchEmpsIdName.pending, (state) => {
      //   state.status = 'loading';
      // })
      .addCase(fetchEmpsIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.empsIdName = action.payload;
      })
      // .addCase(fetchEmpsIdName.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message;
      // })




      .addCase(addEmp.fulfilled, (state, action) => {
        state.emps.push(action.payload);
      })
      .addCase(updateEmp.fulfilled, (state, action) => {
        const index = state.emps.findIndex(emp => emp.id === action.payload.id);
        state.emps[index] = action.payload;
      })
      .addCase(deleteEmp.fulfilled, (state, action) => {
        state.emps = state.emps.filter(emp => emp.id !== action.payload);
      })

      .addCase(changeEmpStatus.fulfilled, (state, action) => {
        const index = state.emps.findIndex((emp) => emp.id === action.payload.id);
        state.emps[index] = action.payload;
    })
  },
});

export default empsSlice.reducer;