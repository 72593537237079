import { Box, Button, FormControlLabel, Grid2 as Grid, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addCreview, updateCreview } from '../../Redux/creviewReducer';
import { useParams } from 'react-router-dom';

const CeoReviewForm = ({currentCreview}) => {
    const {workId}=useParams();
   
    const [review, setReview] = useState('');
    
    const [description, setDescription] = useState('');
   
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();
    // const handleFileChange = (e) => {
    //     setSelectedFile(e.target.files[0]);
    // };
    // const handleMultipleFilesChange = (e) => {
    //     setSelectedFiles(e.target.files);
    // };
    useEffect(() => {
        if (currentCreview) {
            setReview(currentCreview.review);
            
            setDescription(currentCreview.description);   
       
            setActive(currentCreview.active);
        }
    }, [currentCreview]);

    const handleSave = () => {
        const creview = { review,description, active,workId:workId};
        console.log(creview);
        
        if (currentCreview!=null) {
            dispatch(updateCreview({ creviewId: currentCreview.creviewId, creview })).then(() => {
                toast.success('CEO review updated successfully!');  
                // resetForm();
            }).catch((error) => {
                console.log('Failed to add CEO review !');
            });
          
        } else {
            dispatch(addCreview(creview)).then(() => {
            toast.success('CEO review addded successfully!');
            setReview('');
            setDescription(''); 
            // resetForm();
        }).catch((error) => {
            console.log('Failed to add Contractor work!',error);
        });
            
        }
        
    };
    const resetForm=()=>{
        review='';
       
        description='';
       

    }
  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 1024 }}>
         {/* {workId} */}
    <Typography variant='h6'>CEO Directions on Work... <hr/></Typography>
    <Grid container spacing={2}>
    <Grid item size={6}>
    <TextField
                    fullWidth
                    label="Direction"
                    multiline
                    rows={2}
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    margin="normal"
                />
        </Grid>
       

      
   

<Grid item size={6}> <TextField
                    fullWidth
                    autoComplete='off'
                    label="Remarks"
                    value={description}
                    multiline
          rows={2}
                     onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                /> </Grid>

<Grid item size={4}> <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                /></Grid>

<Box height={20}/>
                <Button variant="contained" 
                 onClick={handleSave}              
                >Save</Button>
        </Grid>

    </Box>
  )
}

export default CeoReviewForm