import React, { useState } from "react";

import Navbar from "../../Pages/components/Navbar";
import { Box, Typography } from "@mui/material";
import Sidenav from "./Sidenav";
import InvoiceList from "./InvoiceTable";
import InvoiceForm from "./InvoiceForm";
import InvoiceTable from "./InvoiceTable";
import People from "./People";

const Invoice = () => {
    const [performainvoice, setPerformainvoice] = useState(null);
  return (
    <div>
      <Navbar /> <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h3" align="center">
            {" "}
            Invoice....
          </Typography>
          <hr />
          <InvoiceForm performainvoice={performainvoice} setPerformainvoice={setPerformainvoice}/>
          <InvoiceTable onEdit={setPerformainvoice}/>
          <People/>
        </Box>
      </Box>
    </div>
  );
};

export default Invoice;
