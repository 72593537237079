import React, { useState } from 'react';

import Navbar from '../../Pages/components/Navbar';
import { Box, Typography } from '@mui/material';
import CtvisitForm from './CtvisitForm';
import CtvisitTable from './CtvisitTable';
import Sidenav from './Sidenav';

const Ctvisit = () => {

  const [ctvisit, setCtvisit] = useState(null);
  return (
    <>
    <Navbar/> <Box height={70}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant='h3' align='center'  > Contractor work...</Typography><hr/>
        <Box height={15}/>
         <div className="App">
         <CtvisitForm ctvisit={ctvisit} setCtvisit={setCtvisit}/>
         <CtvisitTable onEdit={setCtvisit} />
         </div>
          
        </Box>
      </Box>
   </>
  );
}

export default Ctvisit;
