import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from './api';

// import {  setCookie,axios } from './apiConfig';



export const fetchDivisions = createAsyncThunk('divisions/fetchDivisions', async (thunkAPI) => {
  // const response = await axios.get(`/api/divisions`);
  // return response.data;


  try {
    const response = await axios.get(`/api/divisions/`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}


});

export const addDivision = createAsyncThunk('divisions/addDivision', async (division,thunkAPI) => {
  // const response = await axios.post(`/api/divisions`, division);
  // return response.data;
  try {
    const response = await axios.post(`/api/divisions/`,division);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

});

export const updateDivision = createAsyncThunk('divisions/updateDivision', async ({ divisionId, division,thunkAPI }) => {
  // const response = await axios.put(`/api/divisions/${id}`, division);
  // return response.data;

  try {
    const response = await axios.put(`/api/divisions/${divisionId}`,division);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const deleteDivision = createAsyncThunk('divisions/deleteDivision', async (divisionId,thunkAPI) => {
  // await axios.delete(`/api/divisions/${divisionId}`);
  // return divisionId;

  try {
    axios.delete(`/api/divisions/${divisionId}`);   
    return divisionId;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

  
}); 

export const changeDivisionStatus = createAsyncThunk('divisions/changeDivisionStatus', async ({ divisionId, active,thunkAPI }) => {
  // const response = await axios.patch(`/api/divisions/${id}/status?active=${active}`);
  // return response.data;


  try {
    const response = await axios.patch(`/api/divisions/${divisionId}/status?active=${active}`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
const divisionsSlice = createSlice({
  name: 'divisions',
  initialState: {
    divisions: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDivisions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDivisions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.divisions = action.payload;
      })
      .addCase(fetchDivisions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDivision.fulfilled, (state, action) => {
        state.divisions.push(action.payload);
      })
      .addCase(updateDivision.fulfilled, (state, action) => {
        const index = state.divisions.findIndex(division => division.divisionId === action.payload.divisionId);
        state.divisions[index] = action.payload;
      })
      .addCase(deleteDivision.fulfilled, (state, action) => {
        state.divisions = state.divisions.filter(division => division.divisionId !== action.payload);
      })

      .addCase(changeDivisionStatus.fulfilled, (state, action) => {
        const index = state.divisions.findIndex((division) => division.divisionId === action.payload.divisionId);
        state.divisions[index] = action.payload;
    })
  },
});

export default divisionsSlice.reducer;