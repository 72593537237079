import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from './api';


// Thunks for file upload and download
export const uploadVideo = createAsyncThunk('videos/uploadVideo', async ({selectedFile,meta}, thunkAPI) => {
    const formData = new FormData();   
    formData.append('title', meta.title);
    formData.append('description', meta.description);
    formData.append("file", selectedFile);   
 
   console.log(meta);
//console.log(formData);
try {
    const response = await axios.post(`/api/v1/videos`,formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    console.log(response.data);
    return response.data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
   
   
    
});

// export const uploadMultipleFiles = createAsyncThunk('files/uploadMultipleFiles', async (files) => {
//     const formData = new FormData();
//     files.forEach((file) => formData.append('files', file));

//     const response = await axios.post(`/api/files/uploadMultiple`, formData, {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     });
//     return response.data;
// });

export const fetchVideos = createAsyncThunk('files/fetchVideos', async () => {
    const response = await axios.get(`/api/v1/videos`);
    return response.data;
});

// export const fetchFilesName = createAsyncThunk('videos/fetchFilesName', async () => {
//     const response = await axios.get(`/api/videos/image/car.png`);
//     console.log(response);
    
//     return response.data;
// });

const videoSlice = createSlice({
    name: 'videos',
    initialState: {
        videos: [],
        status: 'idle',
        error: null,
       
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadVideo.fulfilled, (state, action) => {
                state.videos.push(action.payload);
            })
            
            // .addCase(uploadMultipleFiles.fulfilled, (state, action) => {
            //     state.files = [...state.files, ...action.payload];
            // })
            .addCase(fetchVideos.fulfilled, (state, action) => {
                state.videos = action.payload;
            })
            // .addCase(fetchFilesName.fulfilled, (state, action) => {
            //     state.files1 = action.payload;
            //     console.log("hi");
                
            //     console.log(action.payload);
                
            // })
            ;
    }
});

export default videoSlice.reducer;