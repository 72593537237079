import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  deleteUser,
  changeUserStatus,
} from "../../Redux/userReducer";
import {
  Button,
  List,
  ListUserText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserModal from "./UserModal";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

const UserList = 
() => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    // console.log("users:"+users);
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id))
      .then(() => {
        toast.success("Iten deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add user!");
      });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setSelectedUser(null);
  };
  const handleChangeStatus = (id, active) => {
    dispatch(changeUserStatus({ id, active }))
      .then(() => {
        toast.success("User status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update user status!");
      });
  };

  const handleDialogOpen = (type, userId) => {
    setDialogType(type);
    setCurrentUserId(userId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentUserId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeleteUser(currentUserId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "id", headerName: "ID",  flex:1, hide:true},
    { field: "fullName", headerName: "User Name",  flex:1, },
    { field: "email", headerName: "Email",  flex:1, },
    { field: "roles", headerName: "Role",  flex:1, },
    // { field: 'employee', headerName: 'Mapped Employee', width:300, renderCell: (params) => params?.row?.employee?.name},
    {
      field: "Action1",
      headerName: "Status",
      flex:1,
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
 
    },

    {
      field: "Action",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      flex:1,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
         field: 'Action2',
       headerName: 'Delete',
       flex:1,
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleAdd}>
        Add User <Add/>
      </Button>

      <div style={{ height: 'auto', width: "100%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
          getRowSpacing={()=>'auto'}
        />
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <UserModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentUser={selectedUser}
      />
    </div>
  );
};



export default UserList;