import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import axios from "./api"

 import axios from "axios"
// const API_URL="http://localhost:8080"
 const API_URL = `http://api.erpfmda.in`;

const initialState = {
  peoples: [],
  status: "idle",
  error: null,
}

export const fetchPeoples = createAsyncThunk("people/fetchPeoples", async () => {
  const response = await axios.get(`${API_URL}/auth/peoples`)
  return response.data
})

export const addPeople = createAsyncThunk("people/addPeople", async (peopleData) => {
  const formData = new FormData()
  formData.append("name", peopleData.people.name)
   formData.append("age", JSON.stringify(peopleData.people.age))

  formData.append("file", peopleData.file)
  const response = await axios.post(`${API_URL}/auth/peoples`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return response.data
})

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeoples.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchPeoples.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.peoples = action.payload
      })
      .addCase(fetchPeoples.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addPeople.fulfilled, (state, action) => {
        state.peoples.push(action.payload)
      })
  },
})

export default peopleSlice.reducer