import { Send } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  setRef,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createPerformainvoice, updatePerformainvoice } from "../../Redux/invoiceSlice";

const InvoiceForm = ({ performainvoice, isEdit, setInvoice }) => {
  const [pino, setPino] = useState(performainvoice ? performainvoice.pino : "");
  const [proposalno, setProposalno] = useState(
    performainvoice ? performainvoice.proposalno : ""
  );
  const [dow, setDow] = useState(performainvoice ? performainvoice.dow : "");
  const [dpi, setDpi] = useState(performainvoice ? performainvoice.dpi : "");
  const [efileno, setEfileno] = useState(performainvoice ? performainvoice.efileno : "");
  const [noresource, setNoresource] = useState(
    performainvoice ? performainvoice.noresource : ""
  );
  const [estamt, setEstamt] = useState(performainvoice ? performainvoice.estamt : "");
  const [rdesig, setRdesig] = useState(performainvoice ? performainvoice.rdesig : "");
  const [rpost, setRpost] = useState(performainvoice ? performainvoice.rpost : "");
  const [advpaymentquaterly, setAdvpaymentquaterly] = useState(
    performainvoice ? performainvoice : true
  );
  const[advpaymentquaterlyAmt,setAdvpaymentquaterlyAmt]=useState(performainvoice?performainvoice.advpaymentquaterlyAmt:'');
  const [rtgsno, setRtgsno] = useState(performainvoice ? performainvoice.rtgsno : "");
  const [rtgsdate, setRtgsdate] = useState(performainvoice ? performainvoice.rtgsdate : "");
  const [noteno, setNoteno] = useState(performainvoice ? performainvoice.noteno : "");
  const [taxInvoicefromHatron, setTaxInvoicefromHatron] = useState(
    performainvoice ? performainvoice.taxInvoicefromHatron : true
  );

  const [remark, setRemark] = useState(performainvoice ? performainvoice.remark : "");
  const [active, setActive] = useState(performainvoice ? performainvoice.active : true);

  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setImages(e.target.files);
  };

  useEffect(() => {
    if (performainvoice) {
      setPino(performainvoice.pino);
      setProposalno(performainvoice.proposalno);
      setDow(performainvoice.dow);
      setDpi(performainvoice.dpi);
      setEfileno(performainvoice.efileno);
      setNoresource(performainvoice.noresource);
      setEstamt(performainvoice.estamt);
      setRdesig(performainvoice.rdesig);
      setRpost(performainvoice.rpost);
      setAdvpaymentquaterly(false);
      setAdvpaymentquaterlyAmt(performainvoice.advpaymentquaterlyAmt);
      setRtgsno(performainvoice.rtgsno);
      setRtgsdate(performainvoice.rtgsdate);
      setNoteno(performainvoice.noteno);
      setTaxInvoicefromHatron(false);
      setActive(performainvoice.active);
      setRemark(performainvoice.remark);
    } else {
      setPino("");
      setProposalno("");
      setDow("");
      setDpi("");
      setEfileno("");
      setNoresource("");
      setEstamt("");
      setRdesig("");
      setRpost("");
      setAdvpaymentquaterly(false);
      setAdvpaymentquaterlyAmt("");
      setRtgsno("");
      setRtgsdate("");
      setNoteno("");
      setTaxInvoicefromHatron(false);
      setActive(false);
      setRemark("");
    }
  }, [performainvoice]);



  const handleSubmit = () => {
    const formData = new FormData();
    // formData.append('name', name);
    // formData.append('age', age);
    formData.append(
      "performainvoice",
      new Blob(
        [
          JSON.stringify({
            pino,
            proposalno,
            dow,
            dpi,
            efileno,
            noresource,
            estamt,
            rdesig,
            rpost,
            advpaymentquaterly,
            advpaymentquaterlyAmt,
            rtgsdate,
            rtgsno,
            noteno,
            taxInvoicefromHatron,
            active,
            remark,
           
          }),
        ],
        { type: "application/json" }
      )
    );
    Array.from(images).forEach((image) => {
      formData.append("images", image);
    });

    if (performainvoice) {
    dispatch(updatePerformainvoice({ id: performainvoice.id, performainvoiceData: formData }));
    } else {
        console.log(formData)
      dispatch(createPerformainvoice(formData));
      setPino("");
      setProposalno("");
      setDow("");
      setDpi("");
      setEfileno("");
      setNoresource("");
      setEstamt("");
      setRdesig("");
      setRpost("");
      setAdvpaymentquaterly(false);
      setAdvpaymentquaterlyAmt('');
      setRtgsno("");
      setRtgsdate("");
      setNoteno("");
      setTaxInvoicefromHatron(false);
      setActive(false);
      setRemark("");
    }
  };

  return (
    <>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 1024 }}
      >
        <Typography variant="h6">
          Invoice.. <hr />
        </Typography>
        <Grid container spacing={2}>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="PI No."
              // multiline
              rows={2}
               value={pino}
               onChange={(e) => setPino(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Date of PI"
              // multiline
              type="date"
              rows={2}
               value={dpi}
               onChange={(e) => setDpi(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Proposal No."
              // multiline
              rows={2}
               value={proposalno}
               onChange={(e) => setProposalno(e.target.value)}
              margin="normal"
            />
          </Grid>
          {/* <Grid item size={4}>
            <TextField
              fullWidth
              label="Work Order Id"
              // multiline
              rows={2}
              value={fileno}
              onChange={(e) => setFileno(e.target.value)}
              margin="normal"
            />
          </Grid> */}
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Date of Work Order"            
              type="date"
              rows={2}
               value={dow}
               onChange={(e) => setDow(e.target.value)}
              margin="normal"
            />
          </Grid>

          <Grid item size={4}>
            <TextField
              fullWidth
              label="File No."
              // multiline
              rows={2}
               value={efileno}
               onChange={(e) => setEfileno(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="No. of Resouces"
              type="number"
              rows={2}
              value={noresource}
          onChange={(e) => setNoresource(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Estimate Amount"
              // multiline
              rows={2}
               value={estamt}
               onChange={(e) => setEstamt(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Resource Designation"
              // multiline
              rows={2}
               value={rdesig}
               onChange={(e) => setRdesig(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Resource Post"
              // multiline
              rows={2}
               value={rpost}
               onChange={(e) => setRpost(e.target.value)}
              margin="normal"
            />
          </Grid>

          <Grid item size={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Advance Payment Quaterly</FormLabel>
              <Stack direction={"row"}>
                {" "}
                <RadioGroup
                  row
                  aria-label="advpaymentquaterly"
                  name="taxInvoicefromHatron"
                  // value={formData.gender} onChange={handleChange}
                  defaultValue="yes"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="YES"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                </RadioGroup>
              </Stack>
            </FormControl>
          </Grid>

          {/* <Grid item size={4}>
    <TextField
                    fullWidth
                    label="Advance Payment Quaterly"
                    multiline
                    rows={2}
                    value={nowork}
                    onChange={(e) => setNowork(e.target.value)}
                    margin="normal"
                />
    </Grid> */}
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Advance Payment Quaterly"
              type="number"
              rows={2}
               value={advpaymentquaterlyAmt}
               onChange={(e) => setAdvpaymentquaterlyAmt(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="RTGS No."
              // multiline
              rows={2}
               value={rtgsno}
               onChange={(e) => setRtgsno(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="RTGS Date"
              type="date"
              rows={2}
               value={rtgsdate}
               onChange={(e) => setRtgsdate(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Note No."
             // type="date"
              rows={2}
               value={noteno}
               onChange={(e) => setNoteno(e.target.value)}
              margin="normal"
            />
          </Grid>

          <Grid item size={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tax Invoice From Hartron</FormLabel>
              <Stack direction={"row"}>
                {" "}
                <RadioGroup
                  row
                  aria-label="taxInvoicefromHatron"
                  name="taxInvoicefromHatron"
                  // value={formData.gender} onChange={handleChange}
                  defaultValue="yes"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="YES"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                </RadioGroup>
              </Stack>
            </FormControl>
          </Grid>


          <Grid item size={4}>
            <Box height={5} />
            <input
              type="file"
              multiple
                 onChange={handleFileChange}
            />
          </Grid>
          <Grid item size={4}>
            {" "}
            <FormControlLabel
              control={
                <Switch
                  fullWidth
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item size={4}>
            <Button
              variant="contained"
                onClick={handleSubmit}
            >
              {performainvoice ? "Update" : "Create"}
       
              <Send />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InvoiceForm;
