import React, { useEffect } from 'react'
import WorkList from '../../Xen/components/WorkList'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWorksInfra1 } from '../../Redux/workReducer'
import { DataGrid,  GridToolbar } from '@mui/x-data-grid'
import Sidenav from './Sidenav'
import { render } from '@testing-library/react'
import { Link } from 'react-router-dom'

const ProjectListInfra1 = () => {

  const worksInfra1 = useSelector((state) => state?.works?.worksInfra1);
  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(fetchWorksInfra1())
  },[dispatch])

  const columns = [
   
    { field: "id", headerName: "ID" },
    { 
      field: 'index' , 
      headerName: 'Sr. No.', 
      filterable: true,
      sortable: true,
      renderCell: (index) =>
        index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
  },,
   
    { field: "workname", headerName: "Work Name" ,     width:500,
      renderCell: (params) => 
        <>
        <div style={{
                textAlign: "justify", // Inline style for justification
                whiteSpace: "normal", // Allow text wrapping
                wordBreak: "break-word", // Break long words
              }}>
      {params.value}
        </div>
        </>
    },
    { field: "fileno", headerName: "File No." ,},
     { field: "aramt", headerName: "Approved Amount" ,},
    { field: "vendername", headerName: "Agency Name" ,},
    // { field: "agencyName", headerName: "Agency Name"},
    { field: "startDate", headerName: "Start Date"},
    { field: "timeLimit", headerName: "Time Limit"},
    { field: "dlp", headerName: "DLP"},
    { field: "sdDateComplition", headerName: "Date of Complition"},
    { field: "srDateComplition", headerName: "Rivised Date of Complition"},
    { field: "budgetDYear", headerName: "Budget During Year"},
    { field: "phyStatus", headerName: "Physical Work Status(%) "},
    { field: "finStatus", headerName: "Finacial Work Status(%)"}, 
    { field: "wstatus", headerName: "Project/Work States"},
    { field: "orgname", headerName: "Division" },      
  
  ];


  return (
    <div>

     <Box height={70}/>
     <Box>
     <div style={{ height: 'auto', width: '100%'  }}>
        <DataGrid
       
          rows={worksInfra1}
          columns={columns}
          getRowId={(row) => row.id}     
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
          //checkboxSelection
        />
      </div>
          </Box>
        </div>
  )
}

export default ProjectListInfra1