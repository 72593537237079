import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { addPeople } from '../../Redux/peopleSlice'
import { TextField, Button, Container, Typography } from "@mui/material"

function AddPeople() {
  const [name, setName] = useState("")
  const [age, setAge] = useState("")
  const [file, setFile] = useState(null)
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    const peopleData = {
      people: { name, age: Number.parseInt(age) },
      file,
    }
    dispatch(addPeople(peopleData))
    setName('');
    setAge('');
    setFile(null);
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Add People
        {name}{age}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField label="Name" fullWidth margin="normal" value={name} onChange={(e) => setName(e.target.value)} />
        <TextField
          label="Age"
          type="number"
          fullWidth
          margin="normal"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <input type="file" accept=".pdf" onChange={(e) => setFile(e.target.files[0])} />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add People
        </Button>
      </form>
    </Container>
  )
}

export default AddPeople

