import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchPeoples } from '../../Redux/peopleSlice'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
} from "@mui/material"

function PeopleList() {
  const dispatch = useDispatch()
  const { peoples, status, error } = useSelector((state) => state.people)

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchPeoples())
    }
  }, [status, dispatch])

  if (status === "loading") {
    return <div>Loading...</div>
  }

  if (status === "failed") {
    return <div>Error: {error}</div>
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        People List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>PDF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {peoples.map((people) => (
              <TableRow key={people.id}>
                <TableCell>{people.name}</TableCell>
                <TableCell>{people.age}</TableCell>
                <TableCell>{people.pdfPath}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default PeopleList
