import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


import axios from 'axios';
import { API_BASE_URL } from './api';

// Async actions for CRUD operations
export const fetchCvisits = createAsyncThunk('cvisits/fetchCvisits', async () => {
    const response = await axios.get(`${API_BASE_URL}/api/cvisits`);
    return response.data;
});
export const fetchImages = createAsyncThunk('cvisits/fetchImages', async () => {
    const response = await axios.get(`${API_BASE_URL}/api/cvisits/cimages`);
    return response.data;
});

export const createCvisit = createAsyncThunk('cvisits/createCvisit', async (cvisitData) => {
    const response = await axios.post(`${API_BASE_URL}/api/cvisits`, cvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const updateCvisit = createAsyncThunk('cvisits/updateCvisit', async ({ id, cvisitData }) => {
    const response = await axios.put(`${API_BASE_URL}/api/cvisits/${id}`, cvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const deleteCvisit = createAsyncThunk('cvisits/deleteCvisit', async (id) => {
    await axios.delete(`${API_BASE_URL}/api/cvisits/${id}`);
    return id;
});

const cvisitSlice = createSlice({
    name: 'cvisits',
    initialState: {
        cvisits: [],
        loading: false,
        error: null,
        cimages:[],
       
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCvisits.fulfilled, (state, action) => {
                state.cvisits = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.cimages = action.payload;
            })
            .addCase(createCvisit.fulfilled, (state, action) => {
                state.cvisits.push(action.payload);
            })
            .addCase(updateCvisit.fulfilled, (state, action) => {
                const index = state.cvisits.findIndex(cvisit => cvisit.id === action.payload.id);
                state.cvisits[index] = action.payload;
            })
            .addCase(deleteCvisit.fulfilled, (state, action) => {
                state.cvisits = state.cvisits.filter(cvisit => cvisit.id !== action.payload);
            });
    },
});

export default cvisitSlice.reducer;