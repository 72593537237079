import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridExcelExportOptions } from '@mui/x-data-grid-premium'
import {
  fetchDesigs,
  deleteDesig,
  changeDesigStatus,
} from "../../Redux/desigReducer";
import {
  Button,
  List,
  ListDesigText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DesigModal from "./DesigModal";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

const DesignationList = () => {
  const dispatch = useDispatch();
  const desigs = useSelector((state) => state.desigs.desigs);
  const [selectedDesig, setSelectedDesig] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentDesigId, setCurrentDesigId] = useState(null);

  useEffect(() => {
  //  console.log(desigs);

    dispatch(fetchDesigs());
  }, [dispatch]);

  const handleEdit = (desig) => {
    setSelectedDesig(desig);
    setModalOpen(true);
  };

  const handleDeleteDesig = (id) => {
    dispatch(deleteDesig(id))
      .then(() => {
        toast.success("Designation deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add desig!");
      });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setSelectedDesig(null);
  };
  const handleChangeStatus = (id, active) => {
    dispatch(changeDesigStatus({ id, active }))
      .then(() => {
        toast.success("Desig status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update desig status!");
      });
  };

  const handleDialogOpen = (type, desigId) => {
    setDialogType(type);
    setCurrentDesigId(desigId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentDesigId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeleteDesig(currentDesigId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "designame", headerName: "Designation", width: 290 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "fullName", headerName: "Owner", width: 200 },
  //   { field: 'fullName', headerName: 'Owner', worgtypeIdth:600,
  //     renderCell: (params) => params?.row?.owner?.fullName,
  //  },
    {
      field: "Action",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
       
    },

    {
      field: "Action1",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
        field: 'Action2',
      headerName: 'Delete',
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleAdd}>
        Add Desig <Add/>
      </Button>

      <div sx={{ height: 'auto', width: "100%" }}>
        <DataGrid
          rows={desigs}
          columns={columns}
         getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
   
        />
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DesigModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentDesig={selectedDesig}
      />
    </div>
  );
};



export default DesignationList