import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from './api';

// import { axios, API_BASE_URL } from './apiConfig';


// Async actions for CRUD operations
export const fetchCtvisits = createAsyncThunk('ctvisits/fetchCtvisits', async () => {
    const response = await axios.get(`${API_BASE_URL}/api/ctvisits`);
    return response.data;
});
export const fetchImages = createAsyncThunk('ctvisits/fetchImages', async () => {
    const response = await axios.get(`${API_BASE_URL}/api/ctvisits/ctimages`);
    return response.data;
});

export const createCtvisit = createAsyncThunk('ctvisits/createCtvisit', async (ctvisitData) => {
    const response = await axios.post(`${API_BASE_URL}/api/ctvisits`, ctvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const updateCtvisit = createAsyncThunk('ctvisits/updateCtvisit', async ({ id, ctvisitData }) => {
    const response = await axios.put(`${API_BASE_URL}/api/ctvisits/${id}`, ctvisitData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
});

export const deleteCtvisit = createAsyncThunk('ctvisits/deleteCtvisit', async (id) => {
    await axios.delete(`${API_BASE_URL}/api/ctvisits/${id}`);
    return id;
});

const ctvisitSlice = createSlice({
    name: 'ctvisits',
    initialState: {
        ctvisits: [],
        loading: false,
        error: null,
        ctimages:[],
       
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCtvisits.fulfilled, (state, action) => {
                state.ctvisits = action.payload;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.ctimages = action.payload;
            })
            .addCase(createCtvisit.fulfilled, (state, action) => {
                state.ctvisits.push(action.payload);
            })
            .addCase(updateCtvisit.fulfilled, (state, action) => {
                const index = state.ctvisits.findIndex(ctvisit => ctvisit.id === action.payload.id);
                state.ctvisits[index] = action.payload;
            })
            .addCase(deleteCtvisit.fulfilled, (state, action) => {
                state.ctvisits = state.ctvisits.filter(ctvisit => ctvisit.id !== action.payload);
            });
    },
});

export default ctvisitSlice.reducer;