import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  fetchCreviews } from '../../Redux/creviewReducer';

const CeoReviewList = () => {
  
  const creviews = useSelector((state) => state.creviews.creviews);
  const dispatch=useDispatch();
  useEffect(()=>{
   
dispatch(fetchCreviews())
  },[dispatch])

  const columns = [
    { field: "creviewid", headerName: "ID",flex:1  },

    { 
      field: 'index' , 
      headerName: 'Sr. No.',
    
      renderCell: (index) =>
        index.api.getRowIndexRelativeToVisibleRows(index.row.creviewid) + 1,
  },

    { field: "review", headerName: "Direction",width:400,

      renderCell: (params) => (
        <div
          style={{
            textAlign: "justify", // Inline style for justification
            whiteSpace: "normal", // Allow text wrapping
            wordBreak: "break-word", // Break long words
          }}
        >
          {params.row.review}
        </div>
      ),

      // renderCell: (params) =>  <Link to={`/contractor/cwork/${params.id}`}>{params.value}</Link>
        // <Link to="/contractor/cwork/">{params.row.workname}</Link>,
    },
    { field: "description", headerName: "Remarks" },
     { field: "createdBy", headerName: "Created By",flex:1},
     { field: "created", headerName: "Created Date",
       valueFormatter: 
    //  ({params}) => {
      // (params) => console.log(params.split('-').reverse().join('-'))
      (params) =>new Date( params).toLocaleDateString('en-GB',{ 
        // weekday: 'short', 
       
        
        day: 'numeric', 
        month: '2-digit', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit',
        hour12: true 
      }).toUpperCase().replaceAll(['/'],'-')
     },
    //  { field: "work", headerName: "Work Name",flex:1,

    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         textAlign: "justify", 
    //         whiteSpace: "normal", 
    //         wordBreak: "break-word", 
    //       }}
    //     >
    //       {params?.row?.work?.workname}
    //     </div>
    //   ),
    
       
    //  },
    //  { field: "nod", headerName: "No. of Days"},
    //  { field: "nol", headerName: "No of Labour"},

        
  
  ];
  return (
    <div>

    <Box height={70}/>
    <Box>
    <div style={{ height: 'auto', width: '100%'  }}>
       <DataGrid
         rows={creviews}
         columns={columns}
        getRowId={(row) => row.creviewid}
     
         initialState={{
           pagination: {
             paginationModel: { page: 0, pageSize: 5 },
           },
         }}
         pageSizeOptions={[5, 10, 50, 100]}
         slots={{ toolbar: GridToolbar }}
         getRowHeight={() => 'auto'}
         //checkboxSelection
       />
     </div>
         </Box>
       </div>
  )
}

export default CeoReviewList