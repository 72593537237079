import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from './api';

export const fetchVenders = createAsyncThunk(
  "venders/fetchVenders",
  async (thunkAPI) => {
    // const response = await axios.get(`${BASE_URL}/api/venders`);
    // return response.data;

    try {
      const response = await axios.get(`/api/venders`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addVender = createAsyncThunk(
  "venders/addVender",
  async (vender, thunkAPI) => {
    // const response = await axios.post(`${BASE_URL}/api/venders`, vender);
    // return response.data;
    try {
      const response = await axios.post(`/api/venders`, vender);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateVender = createAsyncThunk(
  "venders/updateVender",
  async ({ id, vender, thunkAPI }) => {
    // const response = await axios.put(`${BASE_URL}/api/venders/${id}`, vender);
    // return response.data;

    try {
      const response = await axios.put(`/api/venders/${id}`, vender);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteVender = createAsyncThunk(
  "venders/deleteVender",
  async (id, thunkAPI) => {
    // await axios.delete(`${BASE_URL}/api/venders/${id}`);
    // return id;

    try {
      await axios.delete(`/api/venders/${id}`);

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changeVenderStatus = createAsyncThunk(
  "venders/changeVenderStatus",
  async ({ id, active, thunkAPI }) => {
    // const response = await axios.patch(`${BASE_URL}/api/venders/${id}/status?active=${active}`);
    // return response.data;

    try {
      const response = await axios.patch(
        `/api/venders/${id}/status?active=${active}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const vendersSlice = createSlice({
  name: "venders",
  initialState: {
    venders: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVenders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVenders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.venders = action.payload;
      })
      .addCase(fetchVenders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addVender.fulfilled, (state, action) => {
        state.venders.push(action.payload);
      })
      .addCase(updateVender.fulfilled, (state, action) => {
        const index = state.venders.findIndex(
          (vender) => vender.id === action.payload.id
        );
        state.venders[index] = action.payload;
      })
      .addCase(deleteVender.fulfilled, (state, action) => {
        state.venders = state.venders.filter(
          (vender) => vender.id !== action.payload
        );
      })

      .addCase(changeVenderStatus.fulfilled, (state, action) => {
        const index = state.venders.findIndex(
          (vender) => vender.id === action.payload.id
        );
        state.venders[index] = action.payload;
      });
  },
});

export default vendersSlice.reducer;
