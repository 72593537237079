import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "./api";
// import axios from "./api";

// import { axios, BASE_URL } from './apiConfig';

const API_URL = `http://api.erpfmda.in`;
//const API_URL = `http://localhost:8080/api/persons`;

export const fetchPersons = createAsyncThunk(
  "persons/fetchPersons",
  async () => {
    const response = await axios.get(`${API_URL}/auth/persons/createdby`);
    return response.data;
  }
);

export const createPerson = createAsyncThunk(
  "persons/createPerson",
  async ({
    pino,
    pidate,
    proposalno,
    dow,
    efileno,
    noresource,
    estamt,
    rdesig,
    rpost,
    advpaymentquaterly,
    advpaymentquaterlyAmt,
    rtgsno,
    rtgsdate,
    noteno,
    taxInvoicefromHatron,
    active,
    createdBy,
    remark,
    file,
  }) => {
    const formData = new FormData();
    formData.append("pino", pino);
    formData.append("pidate", pidate);
    formData.append("proposalno", proposalno);
    formData.append("dow", dow);
    formData.append("efileno", efileno);
    formData.append("noresource", noresource);
    formData.append("estamt", estamt);
    formData.append("rdesig", rdesig);
    formData.append("rpost", rpost);
    formData.append("advpaymentquaterly", advpaymentquaterly);
    formData.append("advpaymentquaterlyAmt", advpaymentquaterlyAmt);
    formData.append("rtgsdate", rtgsdate);
    formData.append("rtgsno", rtgsno);
    formData.append("noteno", noteno);
    formData.append("taxInvoicefromHatron", taxInvoicefromHatron);
    formData.append("active", active);
    formData.append("createdBy", createdBy);
    formData.append("remark", remark);
    formData.append("file", file);

    const response = await axios.post(`${API_URL}/api/persons/create`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response.data);

    return response.data;
  }
);

const personSlice = createSlice({
  name: "persons",
  initialState: { persons: [], loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersons.fulfilled, (state, action) => {
        state.persons = action.payload;
        state.loading = false;
      })
      .addCase(fetchPersons.pending, (state) => {
        state.loading = true;
      })

      .addCase(createPerson.fulfilled, (state, action) => {
        state.persons.push(action.payload);
      });
  },
});

export default personSlice.reducer;
