import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPerson, fetchPersons } from "../../Redux/personSlice";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";


const PersonForm = () => {
  const {auth} = useSelector(store => store);
  const [pino, setPino] = useState("");
  const [pidate, setPidate] = useState("");

  const [proposalno, setProposalno] = useState("");
  const [dow, setDow] = useState("");
  const [efileno, setEfileno] = useState("");
  const [noresource, setNoresource] = useState("");
  const [estamt, setEstamt] = useState("");
  const [rdesig, setRdesig] = useState("");
  const [rpost, setRpost] = useState("");
  const [advpaymentquaterly, setAdvpaymentquaterly] = useState(false);
  const [advpaymentquaterlyAmt, setAdvpaymentquaterlyAmt] = useState("");
  const [rtgsdate, setRtgsdate] = useState("");
  const [rtgsno, setRtgsno] = useState("");
  const [noteno, setNoteno] = useState("");
  const [taxInvoicefromHatron, setTaxInvoicefromHatron] = useState(false);
  const [active, setActive] = useState(false);
  
  const[createdBy,setCreatedBy]=useState(`${auth.user.email}`
  );
  const [remark, setRemark] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
 
  const persons = useSelector((state) => {
    return state.persons.persons;
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      pino &&
      pidate &&
      proposalno &&
      dow &&
      efileno &&
      noresource &&
      estamt &&
      rdesig &&
      rpost &&
      advpaymentquaterly &&
      advpaymentquaterlyAmt &&
      rtgsno &&
      rtgsdate &&
      noteno &&
      taxInvoicefromHatron &&
      active &&   
      createdBy &&   
      remark &&
      file
    ) {
      dispatch(
        createPerson({
          pino,
          pidate,
          proposalno,
          dow,
          efileno,
          noresource,
          estamt,
          rdesig,
          rpost,
          advpaymentquaterly,
          advpaymentquaterlyAmt,
          rtgsno,
          rtgsdate,
          noteno,
          taxInvoicefromHatron,
          active,
          createdBy,
          remark,
       
          file,
        })
      );
      dispatch(fetchPersons());

      setPino("");
      setPidate("");
      setProposalno("");
      setDow("");
      setEfileno("");
      setNoresource("");
      setEstamt("");
      setRdesig("");
      setRpost("");
      setAdvpaymentquaterly("");
      setAdvpaymentquaterlyAmt("");
      setRtgsdate("");
      setRtgsno("");
      setNoteno("");
      setTaxInvoicefromHatron("");
      setActive("");
      setRemark("");
      setFile(null);
    }
  };

  return (
    <>
      <Typography variant="h6" align="center">
        Invoice.. <hr />
      </Typography>
      <Grid container spacing={2} align="center">
        <Grid size={12}>
          <form onSubmit={handleSubmit}>
           
            
          <Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
            <Stack direction={"row"} spacing={2} mt={2}>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="Pi No."
             
                  rows={2}
                  value={pino}
                  onChange={(e) => setPino(e.target.value)}
                  //   margin="normal"
                />

              </Grid>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="PI Date"
                  type="date"
                  value={pidate}
                  onChange={(e) => setPidate(e.target.value)}
                  required
                />
              </Grid>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="Proposal No."
            
                  value={proposalno}
                  onChange={(e) => setProposalno(e.target.value)}
                  required
                />
              </Grid>
              </Stack>
</Grid><Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
              <Stack direction={"row"} spacing={2} mt={2}>
              <Grid size={4}>
                <TextField
                  fullWidth
                  label="Date of Work"
                  type="date"
                  value={dow}
                  onChange={(e) => setDow(e.target.value)}
                  required
                />
              </Grid>
              <Grid item size={4}>
            <TextField
              fullWidth
              label="File No."
        
              rows={2}
               value={efileno}
               onChange={(e) => setEfileno(e.target.value)}
            //   margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="No. of Resouces"
              type="number"
              rows={2}
              value={noresource}
          onChange={(e) => setNoresource(e.target.value)}
            //   margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Estimate Amount"
         
              rows={2}
               value={estamt}
               onChange={(e) => setEstamt(e.target.value)}
            //   margin="normal"
            />
          </Grid>
          </Stack>
          </Grid>
          <Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
          <Stack direction={"row"} spacing={2} mt={2}>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Resource Designation"
       
              rows={2}
               value={rdesig}
               onChange={(e) => setRdesig(e.target.value)}
            //   margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Resource Post"
              // multiline
              rows={2}
               value={rpost}
               onChange={(e) => setRpost(e.target.value)}
            //   margin="normal"
            />
          </Grid>

          <Grid item size={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Advance Payment Quarterly</FormLabel>
              <Stack direction={"row"}>
                {" "}
                <RadioGroup
                  row
                  aria-label="advpaymentquaterly"
                  name="taxInvoicefromHatron"
                  value={advpaymentquaterly}
                  onChange={(e) => setAdvpaymentquaterly(e.target.value)}
                  defaultValue="yes"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="YES"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                </RadioGroup>
              </Stack>
            </FormControl>
          </Grid>
</Stack>
</Grid>
<Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
<Stack direction={"row"} spacing={2} mt={2}>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Advance Payment Quarterly"
              type="number"
              rows={2}
               value={advpaymentquaterlyAmt}
               onChange={(e) => setAdvpaymentquaterlyAmt(e.target.value)}
              // margin="normal"
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="RTGS No."
          
              rows={2}
               value={rtgsno}
               onChange={(e) => setRtgsno(e.target.value)}
           
            />
            <TextField
              // fullWidth
              // label="RTGS No."
          type="hidden"
              // rows={2}
               value={createdBy}
                onChange={(e) => setCreatedBy(e.target.value)}
           
            />
          </Grid>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="RTGS Date"
              type="date"
              rows={2}
               value={rtgsdate}
               onChange={(e) => setRtgsdate(e.target.value)}
            //   margin="normal"
            />
          </Grid>
          </Stack>
          </Grid>
          <Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
          <Stack direction={"row"} spacing={2} mt={2}>
          <Grid item size={4}>
            <TextField
              fullWidth
              label="Note No."
          
              rows={2}
               value={noteno}
               onChange={(e) => setNoteno(e.target.value)}
            //   margin="normal"
            />
          </Grid>
        

          <Grid item size={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Tax Invoice From Hartron</FormLabel>
              <Stack direction={"row"}>
                {" "}
                <RadioGroup
                  row
                  aria-label="taxInvoicefromHatron"
                  name="taxInvoicefromHatron"
                   value={taxInvoicefromHatron} 
                   onChange={(e) => setTaxInvoicefromHatron(e.target.value)}
                  defaultValue="yes"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="YES"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                </RadioGroup>
              </Stack>
            </FormControl>
          </Grid>
          <Grid item size={4}>
            {" "}
            <FormControlLabel
              control={
                <Switch
                  fullWidth
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                />
              }
              label="Active"
            />
          </Grid>
</Stack></Grid>
<Grid item container direction="column" size={{xs:3,sm:4,md:6,lg:12}}  spacing={2}>
<Stack direction={"row"} spacing={2} mt={2}>
          
              <Box height={10} />
              <Grid size={4}>              
                <TextField
                  fullWidth
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                />
              </Grid>
              <Grid item size={4}>
            <TextField
              fullWidth
              label="Remark"
             // type="date"
              rows={2}
               value={remark}
               onChange={(e) => setRemark(e.target.value)}
            //   margin="normal"
            />
          </Grid>
            </Stack>
            </Grid>
            <Box height={10} />
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonForm;
