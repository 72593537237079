import { Button, Grid2 as Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser, register } from "../../../Redux/authSlice";
import { getCookie } from '../../../Redux/apiConfig';

const RegisterForm = ({onClose}) => {
    //const jwt = localStorage.getItem("jwt");
    const jwt= getCookie('jwt');
    const dispatch = useDispatch();
    const {auth} = useSelector(store => store);
    useEffect(() => {
      if (jwt) {
        dispatch(getUser(jwt));
      }
    }, [jwt, auth.jwt]);
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const userData = {
        fullName: data.get("fullName"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        email: data.get("email"),
        password: data.get("password"),
        roles: data.get("roles"),
      };
      dispatch(register(userData));
      onClose();    
      console.log("user data:", userData);
    };
    const navigate = useNavigate();

    const currencies = [
      {
        value: 'CEO',
        label: 'Cheif Executive Office',
      },
      {
        value: 'CE',
        label: 'Cheif Engineer',
      },
      {
        value: 'SE',
        label: 'SUPERIDENT Engineer',
      },
      {
        value: 'XEN',
        label: 'Executive Enginner',
      },
      {
        value: 'SDO',
        label: 'Sub Division Enginner',
      },
      {
        value: 'JE',
        label: 'Junier Enginner',
      },
      {
        value: 'CONTRACTOR',
        label: 'Contractor',
      },
      {
        value: 'ADMIN',
        label: 'Administrator',
      },
   
      {
        value: 'ITADMININVOICE',
        label: 'Invoice Admin',
      },
    ];
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
          <Grid item size={12}>
              <TextField
                required
                id="fullName"
                name="fullName"
                label="Full Name"
                fullWidth
                autoComplete="given name"
              />
            </Grid>
            <Grid item size={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                autoComplete="given name"
              />
            </Grid>
            <Grid item size={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                fullWidth
                autoComplete="given name"
              />
            </Grid>
            <Grid item size={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
              />
            </Grid>
            <Grid item size={12}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                autoComplete="password"
              />
            </Grid>
            <Grid item size={12}>
            <TextField
          id="roles"
          name="roles"
          select
          label="Select"
          defaultValue="JE"
          helperText="Please select your Role"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
            </Grid>
            <Grid item size={12}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{ padding: ".8rem 0", bgcolor: "#9155FD" }}
                className="bg-[#9155fd] w-full"
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </form>
  
        <div className="flex justify-center flex-col items-center">
          <div className="py-3 flex items-center">
            <p>If you have already account?</p>
            <Button
              onClick={() => navigate("/login")}
              className="ml-5"
              size="small"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  export default RegisterForm;