import React from 'react';
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Card, Grid2 as Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Send } from '@mui/icons-material';

const ItadminDash = () => {
  const navigate=useNavigate();

  const dispatch = useDispatch();
  return (
    <>
    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
 
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>IT Invoice Admin Dashbaord</Typography><hr/>
      <Box height={20}/>
        <Grid container spacing={2}>
        <Grid size={6}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Invoices
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/itadmin/invoices")}
                  >
                 View All Invoices<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    {/* <CountUp delay={0.3} end={works.length} duration={2.9} /> */}
                  </Typography>
                </div>
              </Card>
            </Grid>
          
        </Grid>
       
      </Box>
    </Box>
    </>
  );
}

export default ItadminDash;
